import React from "react";
import { AppContext } from "../AppContext";
import { Button, Card, notification } from "antd";
import {
  ReloadOutlined,
  ArrowRightOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { REINIT_SESSION, REMOVE_SESSION } from "../queries";
import { useMutation } from "@apollo/client";
import { Link, useNavigate } from "react-router-dom";

const { Meta } = Card;

const SelectSession = () => {
  const { sessions = [] } = React.useContext(AppContext);

  if (sessions.length === 0) {
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          backgroundColor: "#00a884",
        }}
      >
        <h3> You do not have any session please create one</h3>
        <Link to="/create">
          <Button size="large">Create</Button>
        </Link>
      </div>
    );
  }

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        backgroundColor: "#00a884",
      }}
    >
      <h3>Please select session</h3>
      {sessions.map((session, i) => (
        <SessionCard key={i} session={session} />
      ))}
    </div>
  );
};

export default SelectSession;

const SessionCard = ({ session }) => {
  const { setSession, refetchSessions } = React.useContext(AppContext);
  const navigate = useNavigate();
  const [reinitSession, { loading: reninitLoading }] = useMutation(
    REINIT_SESSION,
    {
      variables: {
        sessionId: session._id,
      },
      onError(err) {
        notification.error({
          message: "Error",
          description: err.message,
        });
      },
      onCompleted(data) {
        notification.success({
          message: "Success",
          description: "Session reinitialized successfully",
        });
        refetchSessions();
      },
    }
  );

  const [deleteSession, { loading: deleteLoading }] = useMutation(
    REMOVE_SESSION,
    {
      variables: {
        sessionId: session._id,
      },
      onError(err) {
        notification.error({
          message: "Error",
          description: err.message,
        });
      },
      onCompleted(data) {
        notification.success({
          message: "Success",
          description: "Session deleted successfully",
        });
        refetchSessions();
      },
    }
  );

  const { status, qr } = session;

  const selectSession = () => {
    setSession(session);
    localStorage.setItem("sessionId", session._id);
    setTimeout(() => {
      navigate("/");
    }, 300);
  };

  const getCover = (_status) => {
    switch (_status) {
      case "CONNECTED":
        return (
          <div style={{ position: "relative" }}>
            <img
              alt="Right Qr Code"
              src="/images/righticon.png"
              width="100px"
            />
          </div>
        );

      case "QR":
        return (
          <>
            <p>
              Please scan the qr code do not move your mobile untill its logged
              in
            </p>
            <img alt="Qr Code" src={qr} />
          </>
        );

      default:
        return (
          <div>
            <h3>{status}</h3>
          </div>
        );
    }
  };

  return (
    <Card
      style={{
        width: 300,
        borderRadius: "10px",
        margin: "10px",
        padding: "20px 10px 0px 10px",
        overflow: "hidden",
      }}
      cover={getCover(status)}
      actions={[
        status === "CONNECTED" ? (
          <ArrowRightOutlined
            style={{ fontSize: "30px", color: "green" }}
            onClick={selectSession}
          />
        ) : (
          <ReloadOutlined
            style={{ fontSize: "20px" }}
            spin={reninitLoading}
            onClick={reinitSession}
          />
        ),
        <DeleteOutlined
          style={{ fontSize: "20px", color: "red" }}
          spin={deleteLoading}
          onClick={deleteSession}
        />,
      ]}
    >
      <Meta
        // avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
        title={<h3>{session.name}</h3>}
      />
    </Card>
  );
};
