import React from "react";
import { Layout } from "antd";
import "../App.less";
import NavBar from "../components/NavBar";
import { AppContext } from "../AppContext";
import { Link, Navigate } from "react-router-dom";
import GlobalAlert from "../components/GlobalAlert";
const { Content } = Layout;

function PrivateLayout({ children }) {
  const token = localStorage.getItem("token");
  const { account, sessions, session } = React.useContext(AppContext);

  if (!token) {
    return <Navigate to="/login" />;
  }

  if (!account) {
    return <Navigate to="/login" />;
  }

  if (!session) {
    return <Navigate to="/select" />;
  }

  if (sessions.length === 0) {
    return <Navigate to="/create" />;
  }

  if (session.status !== "CONNECTED") {
    return (
      <div className="h-screen flex flex-col justify-center item-center">
        <p>
          Please reinitialize your
          <Link to="/select"> sessions </Link>
        </p>
      </div>
    );
  }
  return (
    <>
      <Layout>
        <NavBar />
        <Content style={{ marginTop: 64 }}>
          <GlobalAlert />
          {children}
        </Content>
      </Layout>
    </>
  );
}

export default PrivateLayout;
