import React from "react";
import { Layout } from "antd";
import "../App.less";
import NavBar from "../components/NavBar";
import { AppContext } from "../AppContext";
import { Navigate } from "react-router-dom";
import GlobalAlert from "../components/GlobalAlert";
const { Content } = Layout;

function AuthLayout({ children }) {
  const token = localStorage.getItem("token");
  const { account } = React.useContext(AppContext);

  if (!token) {
    return <Navigate to="/login" />;
  }

  if (!account) {
    return <Navigate to="/login" />;
  }

  return (
    <Layout>
      <NavBar />
      <Content style={{ marginTop: 64 }}>
        <GlobalAlert />
        {children}
      </Content>
    </Layout>
  );
}

export default AuthLayout;
