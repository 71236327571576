import React from "react";
import { Alert } from "antd";

const GlobalAlert = () => {
  return (
    <Alert
      message={`We are experiencing an increase in traffic that is higher than normal. We are working to ensure that our systems are up and running to meet the demand. We apologize for any inconvenience and thank you for your patience. in any case you can reach out at chouhan.rizwan@gmail.com`}
      type="success"
      size="small"
    />
  );
};

export default GlobalAlert;
