import { useMutation } from "@apollo/client";
import { Form, Input, Button, notification } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../AppContext";
import { INITT_SESSION } from "../queries";

const CreateSession = () => {
  const navigate = useNavigate();
  const { sessions, setSessions, refetchSessions } =
    React.useContext(AppContext);
  const [initSession, { loading, error }] = useMutation(INITT_SESSION, {
    onCompleted: (res) => {
      setSessions([...sessions, res.initSession]);
      refetchSessions();
      navigate("/select");
      notification.success({
        message: "Success",
        description: "Session created successfully",
      });
    },
    onError(err) {
      notification.error({
        message: "Error",
        description: err.message,
      });
    },
  });

  const onFinish = (values) => {
    initSession({
      variables: {
        name: values.name,
      },
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        backgroundColor: "#00a884",
      }}
    >
      <div
        className="flex flex-col  justify-around item-center"
        style={{
          minWidth: 300,
          maxWidth: 400,
          boxShadow: "0px 0px 10px #dfdfdf",
          padding: "30px 30px",
          minHeight: 300,
          backgroundColor: "#fff",
          borderRadius: "10px",
          textAlign: "center",
        }}
      >
        <h1>{error ? error.message : null}</h1>
        <h3>You do not have any session please create one</h3>

        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          layout="vertical"
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Enter Session Name"
            name="name"
            rules={[
              { required: true, message: "Please input your session name!" },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              loading={loading}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default CreateSession;
