import React from "react";
import { Routes, Route } from "react-router-dom";
import CreateSession from "./components/CreateSession";
import AuthLayout from "./layouts/AuthLayout";
import PrivateLayout from "./layouts/PrivateLayout";
import PublicLayout from "./layouts/PublicLayout";
import AddContactListPage from "./pages/AddContactListPage";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import SelectSession from "./pages/SelectSession";

const HomeRoute = () => (
  <PrivateLayout>
    <HomePage />
  </PrivateLayout>
);

const LoginRoute = () => (
  <PublicLayout>
    <LoginPage />
  </PublicLayout>
);

const SelectSessionRoute = () => (
  <AuthLayout>
    <SelectSession />
  </AuthLayout>
);

const CreateSessionRoute = () => (
  <AuthLayout>
    <CreateSession />
  </AuthLayout>
);

const AddContactListRoute = () => (
  <PrivateLayout>
    <AddContactListPage />
  </PrivateLayout>
);

function App() {
  return (
    <Routes>
      <Route path="/" element={<HomeRoute />} />
      <Route path="/login" element={<LoginRoute />} />
      <Route path="/select" element={<SelectSessionRoute />} />
      <Route path="/create" element={<CreateSessionRoute />} />
      <Route path="/addcontactlist" element={<AddContactListRoute />} />
      <Route path="*" element={<div>Not Found</div>} />
    </Routes>
  );
}

export default App;
