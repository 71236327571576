import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  from,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { split } from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { onError } from "@apollo/client/link/error";

const wsURI =
  process.env.NODE_ENV !== "production"
    ? "ws://localhost:3001/ws"
    : "wss://api.bulkwa.in/ws";
const httpURI =
  process.env.NODE_ENV !== "production"
    ? "http://localhost:3001/graphql"
    : "https://api.bulkwa.in/graphql";

const wsLink = new GraphQLWsLink(
  createClient({
    url: wsURI,
  })
);

const httpLink = createHttpLink({
  uri: httpURI,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  authLink.concat(httpLink)
);

const logoutLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    // eslint-disable-next-line array-callback-return
    graphQLErrors.map(({ message, extensions }) => {
      if (extensions.code === "UNAUTHENTICATED") {
        localStorage.clear();
        // window.location.href = "/login";
      }
    });
  }

  if (networkError?.statusCode === 401) {
    localStorage.clear();
    // window.location.href = "/login";
  }
});

const apolloClient = new ApolloClient({
  link: from([logoutLink, splitLink]),
  cache: new InMemoryCache(),
});

export default apolloClient;
