import React from "react";
import GoogleLogin from "react-google-login";
import { AppContext } from "../AppContext";
import apolloClient from "../apolloClient";
import { GOOGLE_SIGNING } from "../queries";
import { useNavigate } from "react-router-dom";

const LoginPage = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const { setAccount, refetchSessions } = React.useContext(AppContext);

  const responseGoogle = async (response) => {
    try {
      const { profileObj, googleId } = response;
      const { name, email, imageUrl } = profileObj;
      const { data } = await apolloClient.mutate({
        mutation: GOOGLE_SIGNING,
        variables: {
          name,
          email,
          password: googleId,
          googleId,
          imageLink: imageUrl,
        },
      });
      const account = data.googleSignIn;
      setAccount(account);
      localStorage.setItem("token", account.token);
      refetchSessions();
      navigate("/");
    } catch (error) {
      console.log(error.message);
    }
  };

  const onFailure = (response) => {
    console.log(response);
  };

  return (
    <div
      className="flex flex-col justify-center item-center  h-screen"
      style={{
        background: "url('https://bulkwa.in/assets/images/Banner.png')",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div
        className="flex flex-col  justify-around item-center"
        style={{
          minWidth: 300,
          maxWidth: 400,
          boxShadow: "0px 0px 10px #dfdfdf",
          padding: "30px 30px",
          minHeight: 300,
          backgroundColor: "#fff",
          borderRadius: "10px",
          textAlign: "center",
        }}
      >
        <h2 style={{ fontWeight: 900, fontSize: "2rem" }}>Bulkwa Login</h2>
        <GoogleLogin
          clientId="789152052027-kob5k5lhuk9ho3t15fdnt25rgg09s30c.apps.googleusercontent.com"
          buttonText="Sign in with Google"
          onSuccess={responseGoogle}
          isSignedIn={token ? true : false}
          onFailure={onFailure}
          cookiePolicy={"single_host_origin"}
          className="google-login"
        />
        <p style={{ fontSize: "0.8rem" }}>
          We are planning to add more social login options in the future. Right
          now, the only option is to sign in with Google.
        </p>
      </div>
    </div>
  );
};

export default LoginPage;
