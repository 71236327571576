import React from "react";
import {
  Form,
  Input,
  Button,
  Select,
  Radio,
  Spin,
  Switch,
  Tooltip,
  notification,
} from "antd";
import { Link } from "react-router-dom";
import { GET_CONTACTS_LIST, SEND_MESSAGE, WHATSAPP_CONTACTS } from "../queries";
import { useMutation, useQuery } from "@apollo/client";
import { AppContext } from "../AppContext";
import { PlusOutlined } from "@ant-design/icons";

const { TextArea } = Input;

const HomePage = () => {
  const [form] = Form.useForm();
  // const [mode, setMode] = React.useState("simp le");
  const { session } = React.useContext(AppContext);
  // const [allContacts, setAllContacts] = React.useState(false);
  const {
    data: contactData,
    loading: contactLoading,
    error: contactError,
  } = useQuery(WHATSAPP_CONTACTS, {
    variables: {
      sessionId: session?._id,
    },
  });

  const { data, loading, error } = useQuery(GET_CONTACTS_LIST, {
    variables: {
      sessionId: session?._id,
    },
  });
  const [
    sendMessage,
    { loading: sendMessageLoading, error: sendMessageError },
  ] = useMutation(SEND_MESSAGE, {
    onError(err) {
      notification.error({
        message: "Error",
        description: err.message,
      });
    },
    onCompleted(data) {
      notification.success({
        message: "Success",
        description: "Message sent successfully",
      });
      form.resetFields();
    },
  });

  const onFinish = (values) => {
    sendMessage({
      variables: {
        sessionId: session._id,
        message: values.message,
        chatIds: values.chatIds,
        allContacts: values.allContacts,
        contactListIds: values.contactListIds,
      },
    });
  };

  if (loading || contactLoading) {
    return (
      <div className="h-screen flex flex-col justify-center item-center">
        <Spin size="large" />
      </div>
    );
  }

  if (error || contactError) {
    return (
      <div className="h-screen flex flex-col justify-center item-center">
        <h1>{error?.message || contactError?.message}</h1>
        <p>
          Please reinitialize your
          <Link to="/select"> sessions </Link>
        </p>
      </div>
    );
  }
  const contactLists = data.contactLists || [];
  const contacts = contactData.wacontacts || [];

  return (
    <div className="inner-layout">
      {sendMessageError ? <h3>{sendMessageError.message}</h3> : null}
      <Form
        form={form}
        name="basic"
        layout="vertical"
        initialValues={{
          mode: "simple",
          allContacts: false,
          message:
            "Hello #name! Thank you for taking the time to visit my profile! I hope you have a wonderful day!",
        }}
        onFinish={onFinish}
      >
        <Form.Item
          label="Write Your Text Message"
          name="message"
          rules={[{ required: true, message: "Please input your message!" }]}
        >
          <TextArea
            placeholder="Write your Message"
            autoSize={{ minRows: 3, maxRows: 5 }}
            style={{
              fontSize: "1.2rem",
            }}
          />
        </Form.Item>

        <Form.Item
          name="mode"
          label="Select Contact List Type"
          rules={[{ required: true, message: "Please pick an item!" }]}
        >
          <Radio.Group>
            <Radio.Button value="simple">Simple</Radio.Button>
            <Radio.Button value="custom">Custom</Radio.Button>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.mode !== currentValues.mode
          }
        >
          {({ getFieldValue }) => {
            return getFieldValue("mode") === "simple" ? (
              <>
                <Form.Item
                  name="allContacts"
                  label="Switch on this if you want to send messsages to all of your contacts and groups"
                >
                  <Switch />
                </Form.Item>

                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.allContacts !== currentValues.allContacts
                  }
                >
                  {({ getFieldValue }) =>
                    getFieldValue("allContacts") ? null : (
                      <Form.Item
                        name="chatIds"
                        label="Select contacts to send message"
                        rules={[
                          {
                            required: true,
                            message:
                              "Please select at least one one contact to send message",
                            type: "array",
                          },
                        ]}
                      >
                        <Select
                          mode="multiple"
                          placeholder="Please search and select contacts"
                          fieldNames={{
                            label: "name",
                            value: "id",
                          }}
                          optionFilterProp="name"
                          options={contacts}
                        />
                      </Form.Item>
                    )
                  }
                </Form.Item>
              </>
            ) : (
              <>
                <Form.Item
                  name="contactListIds"
                  label={
                    <Tooltip title="Create Contact List">
                      Select custom contact list
                      <Link to="/addcontactlist">
                        <Button
                          type="primary"
                          shape="circle"
                          style={{ marginLeft: 20 }}
                          icon={<PlusOutlined />}
                        />
                      </Link>
                    </Tooltip>
                  }
                  rules={[
                    {
                      required: true,
                      message:
                        "Please select at least one one contact list to send message",
                      type: "array",
                    },
                  ]}
                >
                  <Select
                    mode="multiple"
                    placeholder="Please search and select contacts"
                    fieldNames={{
                      label: "name",
                      value: "_id",
                    }}
                    optionFilterProp="name"
                    options={contactLists}
                  />
                </Form.Item>
              </>
            );
          }}
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button
            loading={sendMessageLoading}
            type="primary"
            size="large"
            htmlType="submit"
          >
            Send Your Message
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default HomePage;
