import React from "react";
import { Menu, Layout } from "antd";
import { Link, useLocation } from "react-router-dom";
import { AppContext } from "../AppContext";

const { Header } = Layout;

const NavBar = () => {
  const { session } = React.useContext(AppContext);

  const { pathname } = useLocation();

  const routes = ["/create", "/select", "/addcontactlist"];

  const selectedKeys = routes.filter((route) => pathname.includes(route));
  const getSelectedKeyIndex = selectedKeys.map((route) =>
    (routes.indexOf(route) + 1).toString()
  );

  const logout = () => {
    localStorage.removeItem("token");
    window.location.reload();
  };

  return (
    <Header
      style={{
        marginLeft: 0,
        paddingLeft: 10,
        position: "fixed",
        zIndex: 1,
        width: "100%",
      }}
    >
      <Menu
        theme="dark"
        mode="horizontal"
        defaultSelectedKeys={getSelectedKeyIndex}
      >
        <Menu.Item key="1" style={{ fontSize: "2rem", fontWeight: 900 }}>
          <Link to="/">Bulkwa</Link>
        </Menu.Item>
        <Menu.Item key="2">
          <Link to="/create">Create Session</Link>
        </Menu.Item>
        <Menu.Item key="3">
          <Link to="/select">Change Session</Link>
        </Menu.Item>
        <Menu.Item key="4">
          <Link to="/addcontactlist">Create Contact List</Link>
        </Menu.Item>
        <Menu.Item key="5" style={{ marginLeft: "auto" }}>
          {session?.name}({session?.status})
        </Menu.Item>
        <Menu.Item key="6" style={{ marginLeft: "auto" }} onClick={logout}>
          Logout
        </Menu.Item>
        {/* <Menu.Item key="4">
          <Select
            showSearch
            placeholder="Select a person"
            optionFilterProp="children"
            value={session?._id}
            onChange={onChange}
            onSearch={onSearch}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {sessions.map((session) => (
              <Option key={session._id} value={session.id}>
                {session.name}
              </Option>
            ))}
          </Select>
        </Menu.Item> */}
      </Menu>
    </Header>
  );
};

export default NavBar;
