import React from "react";
import {
  Form,
  Input,
  Button,
  Spin,
  Select,
  List,
  Skeleton,
  notification,
  Radio,
} from "antd";
import {
  CREATE_CONTACT_LIST,
  GET_CONTACTS_LIST,
  REMOVE_CONTACT_LIST,
  WHATSAPP_CONTACTS,
} from "../queries";
import { useMutation, useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import { AppContext } from "../AppContext";

const AddContactListPage = () => {
  const [form] = Form.useForm();
  const { session } = React.useContext(AppContext);

  const {
    data: contactData,
    loading: contactLoading,
    error: contactError,
  } = useQuery(WHATSAPP_CONTACTS, {
    variables: {
      sessionId: session?._id,
    },
  });

  const {
    data,
    loading,
    error,
    refetch: refetchContactList,
  } = useQuery(GET_CONTACTS_LIST, {
    variables: {
      sessionId: session?._id,
    },
  });

  const [createContactList, { loading: createContactListLoading }] =
    useMutation(CREATE_CONTACT_LIST, {
      onError(err) {
        notification.error({
          message: "Error",
          description: err.message,
        });
      },
      onCompleted(data) {
        notification.success({
          message: "Success",
          description: "Contact list created successfully",
        });
        form.resetFields();
        refetchContactList();
      },
    });

  const onFinish = (values) => {
    createContactList({
      variables: {
        sessionId: session._id,
        name: values.name,
        chatIds: values.chatIds,
      },
    });
  };

  if (loading || contactLoading) {
    return (
      <div className="h-screen flex flex-col justify-center item-center">
        <Spin size="large" />
      </div>
    );
  }

  if (error || contactError) {
    return (
      <div className="h-screen flex flex-col justify-center item-center">
        <h1>{error?.message || contactError?.message}</h1>
        <p>
          Please reinitialize your
          <Link to="/select"> sessions </Link>
        </p>
      </div>
    );
  }

  const contactLists = data.contactLists || [];

  const contacts = contactData?.wacontacts || [];

  return (
    <div className="inner-layout">
      <h3>Create your custom contact lists</h3>
      <Form form={form} name="basic" onFinish={onFinish} layout="vertical">
        <Form.Item
          label="Enter Contact List Name"
          name="name"
          rules={[
            {
              required: true,
              message: "Please input your contact list name!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Bulk Select" name="bulkselect">
          <Radio.Group
            onChange={(e) => {
              console.log("changed", e.target.value);
              const bulkselect = e.target.value;
              const chatIds = [];
              contacts.forEach((contact) => {
                const chatId = contact.id;
                if (
                  chatId.indexOf("g.us") !== -1 &&
                  bulkselect === "all-groups"
                ) {
                  chatIds.push(chatId);
                } else {
                  if (bulkselect === "all-contacts") {
                    chatIds.push(chatId);
                  }
                }
              });
              console.log("chatIds", chatIds);
              form.setFieldsValue({
                chatIds,
              });
            }}
          >
            <Radio value={"all-contacts"}>Select All personal Contacts</Radio>
            <Radio value={"all-groups"}>Select All groups </Radio>
            <Radio value={"custom"}>Custom</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name="chatIds"
          label="Select contacts to send message"
          rules={[
            {
              required: true,
              message:
                "Please select at least one one contact to create batch of contact list!",
              type: "array",
            },
          ]}
        >
          <Select
            mode="multiple"
            placeholder="Please search and select contacts"
            fieldNames={{
              label: "name",
              value: "id",
            }}
            optionFilterProp="name"
            options={contacts}
          />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button
            type="primary"
            loading={createContactListLoading}
            size="large"
            htmlType="submit"
          >
            Submit
          </Button>
        </Form.Item>
      </Form>

      <List
        loading={loading}
        itemLayout="horizontal"
        // loadMore={loadMore}
        dataSource={contactLists}
        renderItem={(item) => (
          <ContactListItem
            item={item}
            refetchContactList={refetchContactList}
          />
        )}
      />
    </div>
  );
};

export default AddContactListPage;

const ContactListItem = ({ item, refetchContactList }) => {
  const [removeContactList, { loading }] = useMutation(REMOVE_CONTACT_LIST, {
    variables: {
      contactListId: item._id,
    },
    onError(err) {
      notification.error({
        message: "Error",
        description: err.message,
      });
    },
    onCompleted(data) {
      notification.success({
        message: "Success",
        description: "Contact list removed successfully",
      });
      refetchContactList();
    },
  });

  return (
    <List.Item
      actions={[
        <Button loading={loading} type="ghost" onClick={removeContactList}>
          Delete
        </Button>,
      ]}
      style={{ borderBottom: "1px solid #dfdfdf" }}
    >
      <Skeleton title={false} loading={item.loading} active>
        <List.Item.Meta
          title={`${item.name} (${item?.contacts?.length})`}
          description={item.contacts
            .slice(0, 5)
            .map((contact) => contact.name + ", ")}
        />
      </Skeleton>
    </List.Item>
  );
};
