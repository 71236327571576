import { useQuery } from "@apollo/client";
// import { Spin } from "antd";
import React from "react";
// import { useGoogleLogin } from "react-google-login";
// import apolloClient from "./apolloClient";
import {
  // GOOGLE_SIGNING,
  SESSIONS,
  SESSION_STATUS,
} from "./queries";

const AppContext = React.createContext(() => {});

const AppProvider = ({ children }) => {
  const [account, setAccount] = React.useState(null);
  const [sessions, setSessions] = React.useState([]);
  const [session, setSession] = React.useState(null);

  const { subscribeToMore, refetch: refetchSessions } = useQuery(SESSIONS, {
    variables: {},
    // skip: !token,
    onCompleted: (res) => {
      const { sessions } = res;
      const sessionIds = sessions.map((session) => session._id);

      setSessions(sessions);
      const savedSessionId = localStorage.getItem("sessionId");
      const savedSession = sessions.find(
        (session) => session?._id === savedSessionId
      );
      if (savedSession) {
        setSession(savedSession);
      }
      subscribeToMore({
        document: SESSION_STATUS,
        variables: { sessionIds: sessionIds },
        updateQuery: (prev, { subscriptionData }) => {
          // if (!subscriptionData.data) return prev;
          // const { sessionStatus } = subscriptionData.data;
          // const { session } = sessionStatus;
          // setSession(session);
          // return {
          //   ...prev,
          //   sessions: [...prev.sessions, session],
          // };
        },
      });
    },
  });

  // const { signIn, loaded } = useGoogleLogin({
  //   onSuccess: async (response) => {
  //     try {
  //       const { profileObj, googleId } = response;
  //       const { name, email, imageUrl } = profileObj;
  //       const { data } = await apolloClient.mutate({
  //         mutation: GOOGLE_SIGNING,
  //         variables: {
  //           name,
  //           email,
  //           password: googleId,
  //           googleId,
  //           imageLink: imageUrl,
  //         },
  //       });
  //       const account = data.googleSignIn;
  //       setAccount(account);
  //       localStorage.setItem("token", account.token);
  //       refetchSessions();
  //       setLoading(false);
  //     } catch (error) {
  //       console.log(error.message);
  //     }
  //   },
  //   isSignedIn: token ? true : false,
  //   onAutoLoadFinished: (response) => {
  //     // console.log("onAutoLoadFinished");
  //     // console.log(response);
  //     setLoading(false);
  //   },
  //   clientId:
  //     "789152052027-kob5k5lhuk9ho3t15fdnt25rgg09s30c.apps.googleusercontent.com",
  //   onFailure: (response) => {
  //     // console.log("onFailure");
  //     // console.log(response);
  //     setLoading(false);
  //   },
  //   uxMode: "popup",
  //   scope: "https://www.googleapis.com/auth/userinfo.email",
  //   accessType: "offline",
  //   responseType: "code",
  //   onRequest: (response) => {
  //     // console.log("onRequest");
  //     // console.log(response);
  //   },
  //   prompt: "consent",
  // });

  // React.useEffect(() => {
  //   // console.log("useEffect");
  //   // console.log(loaded);
  //   const token = localStorage.getItem("token");
  //   if (loaded && token) {
  //     // signIn();
  //   }
  // }, [loaded, signIn]);
  // console.log(account);
  // console.log(sessions);

  return (
    <AppContext.Provider
      value={{
        account,
        setAccount,
        sessions,
        setSessions,
        session,
        setSession,
        refetchSessions,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
