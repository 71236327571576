import { gql } from "@apollo/client";

export const GOOGLE_SIGNING = gql`
  mutation googleSignIn(
    $name: String!
    $email: String!
    $password: String!
    $googleId: String!
    $imageLink: String!
  ) {
    googleSignIn(
      name: $name
      email: $email
      password: $password
      googleId: $googleId
      imageLink: $imageLink
    ) {
      _id
      name
      email
      password
      googleId
      status
      createdAt
      updatedAt
      token
    }
  }
`;

export const SESSION_STATUS = gql`
  subscription sessionStatus($sessionIds: [String]!) {
    sessionStatus(sessionIds: $sessionIds) {
      _id
      status
      qr
    }
  }
`;

export const SESSIONS = gql`
  query sessions {
    sessions {
      _id
      name
      accountId
      qr
      status
    }
  }
`;

export const SESSION = gql`
  query getSession($sessionId: String!) {
    getSession(sessionId: $sessionId) {
      _id
      name
      accountId
      qr
      status
    }
  }
`;

export const INITT_SESSION = gql`
  mutation InitSession($name: String!) {
    initSession(name: $name) {
      _id
      name
      accountId
      qr
      status
    }
  }
`;

export const REINIT_SESSION = gql`
  mutation reinitSession($sessionId: String!) {
    reinitSession(sessionId: $sessionId) {
      _id
      name
      accountId
      qr
      status
    }
  }
`;

export const REMOVE_SESSION = gql`
  mutation RemoveSession($sessionId: String!) {
    removeSession(sessionId: $sessionId)
  }
`;

export const GET_QR_CODE = gql`
  query getQRCode {
    getQRCode
  }
`;

export const GET_GROUPS = gql`
  query getGroups {
    getGroup {
      id
      name
    }
  }
`;

export const CREATE_MESSAGE_QUEUE = gql`
  mutation createMessageQueue(
    $sessionId: String!
    $message: String!
    $numbers: [String]
  ) {
    createMessageQueue(
      sessionId: $sessionId
      message: $message
      numbers: $numbers
    ) {
      _id
      message
      sessionId
      excel
      numbers
      percentage
    }
  }
`;

export const GET_MESSAGE_QUEUES = gql`
  query getMessageQueues {
    getMessageQueues {
      _id
      message
      sessionId
      excel
      numbers
      percentage
    }
  }
`;

export const GET_MESSAGE_QUEUE = gql`
  query getMessageQue($id: String!) {
    getMessageQueue(_id: $id) {
      _id
      message
      sessionId
      excel
      numbers
      percentage
    }
  }
`;

export const WHATSAPP_CONTACTS = gql`
  query Wacontacts($sessionId: String!) {
    wacontacts(sessionId: $sessionId) {
      id
      name
      isBusiness
      isMyContact
      isWAContact
      profilePicThumbObj {
        eurl
        id
        img
        imgFull
        raw
      }
    }
  }
`;

export const SEND_MESSAGE = gql`
  mutation SendMessage(
    $sessionId: String!
    $message: String!
    $chatIds: [String]
    $allContacts: Boolean
    $contactListIds: [String]
  ) {
    sendMessage(
      sessionId: $sessionId
      message: $message
      chatIds: $chatIds
      allContacts: $allContacts
      contactListIds: $contactListIds
    )
  }
`;

export const GET_CONTACTS_LIST = gql`
  query ContactLists($sessionId: String!) {
    contactLists(sessionId: $sessionId) {
      _id
      name
      accountId
      sessionId
      contacts {
        id
        name
        isBusiness
        isMyContact
        isWAContact
        profilePicThumbObj {
          eurl
          id
          img
          imgFull
          raw
        }
      }
      totalCount
    }
  }
`;

export const CREATE_CONTACT_LIST = gql`
  mutation CreateContactList(
    $sessionId: String!
    $name: String!
    $chatIds: [String]!
  ) {
    createContactList(sessionId: $sessionId, name: $name, chatIds: $chatIds) {
      _id
      name
      accountId
      sessionId
      contacts {
        id
        name
        isBusiness
        isMyContact
        isWAContact
        profilePicThumbObj {
          eurl
          id
          img
          imgFull
          raw
        }
      }
      totalCount
    }
  }
`;

export const REMOVE_CONTACT_LIST = gql`
  mutation RemoveContactList($contactListId: String!) {
    removeContactList(contactListId: $contactListId)
  }
`;
